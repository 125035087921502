<script>
import t1 from "@/assets/common/boost.png"
import t2 from "@/assets/common/host.png"
import t3 from "@/assets/common/cashback.png"
import t4 from "@/assets/common/levelup.png"
import t5 from "@/assets/common/bespoke.png"
import s1 from "@/assets/common/stat3.svg"
import s2 from "@/assets/common/stat1.svg"
import s3 from "@/assets/common/stat2.svg"
import s4 from "@/assets/common/stat4.svg"
import MainTabBar from "@/components/tabbar/MainTabBar.vue";
import HeaderNav from "@/components/HeaderNav.vue";

export default {
    name: "vip",
    components: {HeaderNav, MainTabBar},
    data() {
        return {
            level: 0,
            svgList: [s1, s2, s3, s4],
            integralList: [
                {t: '1USDT', i: 1},
                {t: '7CNY', i: 1},
                {t: '24900VND', i: 1},
                {t: '15700IDR', i: 1},
                {t: '33THB', i: 1},
                {t: '149JPY', i: 1},
                {t: '1350KRW', i: 1},
                {t: '84INR', i: 1},
            ],
            txtList: [
                {
                    img: t1,
                    title: '助推奖金',
                    content: '您可以预期每周和每月根据您的游戏量获得奖金。玩得越多，获得的奖金就越多。'
                },
                {
                    img: t2,
                    title: '专属 VIP 服务代表',
                    content: '被分配一名将支持并满足您的投注需求的专属 VIP 服务代表。'
                },
                {
                    img: t3,
                    title: '近期游戏表现奖金',
                    content: '运气不佳？Stake 会在您每次升级时根据您的损失提供额外资金。'
                },
                {
                    img: t4,
                    title: '升级奖金',
                    content: '在每达到下个级别后获取奖金。所达级别越高，升级奖金就越大。'
                },
                {
                    img: t5,
                    title: 'Bespoke',
                    content: '与您的专属 VIP 服务代表合作，根据您的投注需求定制福利。'
                },
            ],
            starList: [
                {
                    p1: 'Bronze',
                    p2: '$10k',
                    p4: 'Monthly Bonuses',
                    p5: 'Level Up Bonuses',
                    p6: 'Rakeback',
                    p7: 'Weekly Bonuses',
                },
                {
                    p1: 'Silver',
                    p2: '$50k - $100k',
                    p4: 'Monthly Bonuses',
                    p5: 'Level Up Bonuses',
                    p6: 'Rakeback',
                    p7: 'Weekly Bonuses',
                },
                {
                    p1: 'Gold',
                    p2: '$100k',
                    p4: 'Monthly Bonuses',
                    p5: 'Level Up Bonuses',
                    p6: 'Rakeback',
                    p7: 'Weekly Bonuses',
                },
                {
                    p1: 'Platinum I-III',
                    p2: '$250k - $1M',
                    p4: 'Monthly Bonuses',
                    p5: 'Level Up Bonuses',
                    p6: 'Rakeback',
                    p7: 'Weekly Bonuses',
                },
            ]

        }
    },
    methods: {},
    computed: {
        user() {
            return this.$store.state.member
        }
    },
    created() {
        if (this.user && this.user !== 'null') {
            this.level = Number(this.user.level ) * 10
        }
    }
}
</script>

<template>
    <div class="vip">
        <div class="box222">
            <HeaderNav></HeaderNav>
        </div>
        <div class="card">
            <div>{{ user.nickname === '' ? '未设置昵称' : user.nickname }}</div>
            <div class="t1">
                <span>您的VIP进度</span>
                <span>50.00%</span>
            </div>
            <div class="t2">
                <div>
                    <Progress :hide-info="true" :percent="level"/>
                </div>
                <p class="t2-1">
                    <span>当前等级</span>
                    <span>铜</span>
                </p>
            </div>
        </div>
        <div class="rank">
            <div class="rank-title">
                Mago VIP 排名系统
            </div>
            <div class="welfare">
                <div class="welfare-con">
                    <div class="top">
                        <div v-for="(item,i) in svgList" class="svgL" :key="i">
                            <img class="star-svg" :src="item" alt="">
                            <div class="line"></div>
                        </div>
                    </div>
                    <div class="card-list">
                        <div class="card-item" v-for="(item,i) in starList" :key="i">
                            <p class="p1">{{ item.p1 }}</p>
                            <p class="p2">{{ item.p2 }}</p>
                            <p class="p3">投注金额</p>
                            <p class="p4">{{ item.p4 }}</p>
                            <p class="p5">{{ item.p5 }}</p>
                            <p class="p6">{{ item.p6 }}</p>
                            <p class="p7">{{ item.p7 }}</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="integral">
            <div class="rank">
                <div class="rank-title">
                    Mago 积分系统
                </div>
            </div>
            <div class="title">积分</div>
            <div class="table">
                <div class="table-item" v-for="(item ,i ) in integralList" :key="i">
                    <div>{{ item.t }}</div>
                    <div>{{ item.i }}积分</div>
                </div>
            </div>
        </div>
        <div class="rank">
            <div class="rank-title">
                Mago VIP 俱乐部福利
            </div>
            <div class="wrapper" v-for="(item,k)  in txtList" :key="k">
                <div><img class="t-img" :src="item.img" alt=""></div>
                <div>
                    <p class="t-txt">{{ item.title }}</p>
                    <p class="c-txt">{{ item.content }}</p>
                </div>
            </div>
        </div>
        <Main-tab-bar></Main-tab-bar>
    </div>
</template>

<style scoped lang="scss">
.vip {
    display: flex;
    flex-direction: column;
    color: #fff;
    font-weight: 500;
    background-color: #1A2C38;
    padding: 60px 0 68px 0;

    .card {
        border-radius: 5px;
        width: 90%;
        margin: 10px 5%;
        padding: 20px 10%;
        height: 186px;
        border: 2px solid rgb(47, 69, 83);

        .t1 {
            margin-top: 30px;
            display: flex;
            justify-content: space-between;
        }

        .t2 {
            margin-top: 20px;
            display: flex;
            flex-direction: column;
            justify-content: space-between;

            .t2-1 {
                margin-top: 6px;
                display: flex;
                justify-content: space-between;
            }
        }
    }

    .rank {
        margin-top: 20px;
        background-color: #1A2C38;

        .rank-title {
            text-align: center;
            padding: 20px;
            font-size: 20px;
            font-weight: 800;
        }

        .welfare {
            height: 300px;
            width: 96%;
            margin: auto;
            overflow-x: scroll;

            .welfare-con {
                width: 250%;
                height: 100%;
                display: flex;
                flex-direction: column;

                .top {
                    padding: 20px;
                    display: flex;
                    justify-content: space-around;

                    .star-svg {
                        width: 30px;
                    }

                    .svgL {
                        width: 24%;
                        display: flex;
                        align-items: center;

                        .line {
                            flex: 1;
                            height: 2px;
                            background-color: #233743;
                            //width: 100px;
                        }
                    }

                }

                .card-list {
                    width: 100%;
                    height: 100%;
                    display: flex;
                    justify-content: space-around;

                    .card-item {
                        height: 100%;
                        width: 24%;
                        display: flex;
                        background-color: #233743;
                        flex-direction: column;
                        padding: 20px;

                        .p1 {
                            background-color: #C59C6C;
                            padding: 2px 4px;
                            border-radius: 4px;
                            width: 70px;
                            text-align: center;
                            color: #000;
                            font-size: 12px;
                        }

                        .p2 {
                            font-size: 28px;
                            font-weight: 800;
                        }

                        .p3 {
                            color: #b1bad3;
                        }

                        .p4 {
                            margin-top: 8px;
                        }
                    }

                    .card-item:nth-child(2) {
                        .p1 {
                            background-color: #B2CCCC;
                        }
                    }

                    .card-item:nth-child(3) {
                        .p1 {
                            background-color: #FFD100;
                        }
                    }

                    .card-item:nth-child(4) {
                        .p1 {
                            width: 100px;
                            background-color: #6FDDE7;
                        }
                    }
                }
            }
        }

        .wrapper {
            padding: 0 10px;
            width: 94%;
            height: 100px;
            margin: 10px auto;
            display: flex;
            border-radius: 3px;
            background-color: #213743;
            align-items: center;

            .t-img {
                margin-right: 10px;
            }

            .t-txt {
                font-size: 16px;
                font-weight: 800;
            }

            .c-txt {
                font-size: 12px;
                color: #BAB1D3;
            }
        }
    }

    .integral {
        width: 90%;
        margin-left: 5%;

        .table-item {
            display: flex;
            height: 30px;
            line-height: 30px;

            & > div {
                width: 50%;
                text-align: center;
                border: 1px solid #ccc;
                border-bottom: none;
            }

            & > div:nth-child(1) {
                border-right: none;
            }
        }

        .table-item:last-child {
            & > div {
                border: 1px solid #ccc;
            }
        }
    }
}
</style>
